
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import { DataEmitterService } from "@/services/DataEmiterService";
import Editor from '@tinymce/tinymce-vue'
import OrganizationsUtil from '@/utils/OrganizationsUtil'
import * as moment from "moment";
import DateUtil from '@/utils/DateUtil'

export default {

  components: {
    Multiselect,
    'editor': Editor
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) )

    return {
      members: [],
      organizationsSnippet: snippets ?? {},
      organizationsList: [],
      reportHeader: [],
      paginatedOrganizationsList: [],
      paginatedSearchedOrganizationsList: [],
      organizationsPaginatedList: [],
      rolesList: [],
      organizationsListUntouched: [],
      searchedData: [],
      model: +sessionStorage.getItem( 'model' ) ?? 0,
      searchField: '',
      newEventName: '',
      addOrEditSpace: '',
      currentSpace: {
        id: '',
        name: '',
        description: '',
        qrCode: '',
        floorId: null,
        mapPosition: null,
        maxNumberOfParticipants: null,
        isBookable: true,
        isInvitational: true,
        isEventCapable: true,
        photos: [],
        services: [],
        photoIds: [],
      },
      defaultSpace: {
        id: '',
        name: '',
        description: '',
        qrCode: '',
        floorId: null,
        mapPosition: null,
        maxNumberOfParticipants: null,
        isBookable: true,
        isInvitational: true,
        isEventCapable: true,
        photos: [],
        services: [],
        photoIds: [],
      },
      dialog: false,
      spaceDialog: false,
      eventDialog: false,
      editEventDialog: false,
      addEventsDialog: false,
      dialogDelete: false,
      dialogDeleteSpace: false,
      dialogDeletePhoto: false,
      dialogCarousel: false,
      deleteEventDialog: false,
      dialogUpgrade: false,
      submitted: false,
      globalError: true,
      eventsSubmitted: false,
      emptyReportData: false,
      spaceSubmitted: false,
      mobileNavBar: false,
      organizationId: this.$route.params.id,
      editedIndex: -1,
      toDeleteSpace: {},
      editedItem: {
        creditsForBookings: null,
        creditsForEvents: null,
        email: null,
        fullAddress: null,
        facilities: [],
        membershipEndDate: null,
        membershipStartDate: null,
        name: null,
        phoneNumber: null,
        sdiCode: null,
        vatNumber: null
      },
      defaultItem: {
        creditsForBookings: null,
        creditsForEvents: null,
        email: null,
        fullAddress: null,
        membershipEndDate: null,
        membershipStartDate: null,
        name: null,
        phoneNumber: null,
        sdiCode: null,
        vatNumber: null
      },
      timeRegex: /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
      multiSelectRoles: [],
      carouselPhotos: [],
      sortBy: 'userId',
      editedPrefix: '',
      sortDesc: true,
      customSort( items, index, isDescending ) {

        if ( index[0] !== undefined ) {
          if ( index[0] === 'createDate' || index[0] === 'updateDate' ) {
            if ( this.sortDesc ) {
              return items.sort( ( a, b ) => new Date( b[index] ) - new Date( a[index] ) )
            } else {
              return items.sort( ( a, b ) => new Date( a[index] ) - new Date( b[index] ) )
            }
          } else if ( index[0] === '' || index[0] === 'id' ) {

            if ( this.sortDesc ) {
              return items.sort( ( a, b ) => b.id - a.id )
            } else {
              return items.sort( ( a, b ) => a.id - b.id )
            }

          } else if ( index[0] && index[0]?.includes( '.' ) ) {
            let i = index[0].split( '.' )[0]

          } else {

            if ( isDescending[0] === true ) {
              return items.sort( ( a, b ) => a[index]?.toLowerCase().localeCompare( b[index]?.toLowerCase() ) )
            } else if ( isDescending[0] === false ) {
              return items.sort( ( a, b ) => b[index]?.toLowerCase().localeCompare( a[index]?.toLowerCase() ) )
            }

          }
        } else {

          if ( isDescending[0] === true ) {
            return items.sort( ( a, b ) => a[index]?.toLowerCase().localeCompare( b[index]?.toLowerCase() ) )
          } else if ( isDescending[0] === false ) {
            return items.sort( ( a, b ) => b[index]?.toLowerCase().localeCompare( a[index]?.toLowerCase() ) )
          }

        }

        return items
      },
      errorKey: '',
      addEventsDialogErrorKey: '',
      toDeletePhotoIndex: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldOrganization: {},
      editedEventItem: {},
      toDeleteEvent: {},
      addressesDataList: [],
      showSpaces: [],
      organizationSpaces: [],
      organizationServices: [],
      eventsList: [],
      eventsNameList: [],
      oldEventsList: [],
      chips: [],
      chipsNameList: [],
      oldChips: [],
      eventsListHasBeenEdited: false,
      reportPopup: '',
      reportDate: '',
      reportEndDate: '',
      reportStartDate: '',
      fav: true,
      menu: false,
      message: false,
      hints: true,
      selectedFacilities: [],
      facilities: [],
    }
  },

  computed: {

    maxDate() {
      const today = new Date().toISOString().split("T")[0];
      return today;
    },

    defaultSpaceImage() {
      return require( '../../assets/images/facilities/no-image.svg' );
    },

    memberHeaders() {
      return [
        { text: this.organizationsSnippet.id, value: 'userId', width: '0px', sortable: true },
        { text: this.organizationsSnippet.roles, value: 'roles', width: '0px', sortable: true },
        { text: this.organizationsSnippet.email, value: 'email', width: '0px', sortable: true },
        {
          text: this.organizationsSnippet.firstName,
          value: 'firstName',
          width: '0px',
          sortable: true
        },
        {
          text: this.organizationsSnippet.lastName,
          value: 'lastName',
          width: '0px',
          sortable: true
        },
        {
          text: this.organizationsSnippet.phoneNumber,
          value: 'phoneNumber',
          width: '0px',
          sortable: true
        },
        {
          text: this.organizationsSnippet.createDate,
          value: 'createDate',
          width: '0px',
          sortable: true
        },
        {
          text: this.organizationsSnippet.actions,
          value: 'actions',
          width: '0px',
          sortable: false
        },
      ]
    },

    headers() {
      return [
        { text: this.organizationsSnippet.id, value: 'id', width: '5%' },
        { text: this.organizationsSnippet.name, value: 'name', width: '10%' },
        { text: this.organizationsSnippet.fullAddress, value: 'fullAddress', width: '13%' },
        { text: this.organizationsSnippet.email, value: 'email', width: '13%' },
        { text: this.organizationsSnippet.phoneNumber, value: 'phoneNumber', width: '10%' },
        { text: this.organizationsSnippet.vatNumber, value: 'vatNumber', width: '10%' },
        { text: this.organizationsSnippet.sdiCode, value: 'sdiCode', width: '10%' },
        {
          text: this.organizationsSnippet.creditsForEvents,
          value: 'creditsForEvents',
          width: '13%'
        },
        {
          text: this.organizationsSnippet.creditsForBookings,
          value: 'creditsForBookings',
          width: '13%'
        },
        {
          text: this.organizationsSnippet.actions,
          value: 'actions',
          width: '10%',
          sortable: false
        },
      ]
    },
  },

  watch: {

    spaceDialog( val ) {
      val || this.closeSpaceDialog()
    },

    model( val ) {
      sessionStorage.setItem( 'model', val );
    },


    dialogCarousel( val ) {
      val || this.closeDialogCarousel()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },
    dialogDeletePhoto( val ) {
      val || this.closeDelete()
    },

    deleteEventDialog( val ) {
      val || this.closeDeleteEvent()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

  },

  beforeRouteLeave( to, from, next ) {
    sessionStorage.removeItem( 'model' );
    DataEmitterService.$emit( 'route-change' );

    if ( this.$route.path.includes( 'organization-details' ) ) {
      return next();
    }

    StorageService.deleteOrganizationFromStorage();
    next()
  },

  async mounted() {
    this.organizationsUtil = new OrganizationsUtil( 'api/admin-organizations' );
    await this.getFacilitiesApi();
    await this.getOrganizationApi();

    this.loading = false;
  },

  methods: {

    openAddNewSpaceDialog( floorId ) {
      this.spaceDialog = true;
      this.currentSpace.floorId = floorId;
    },

    setSpacePhotos( files ) {
      if ( files ) {
        this.currentSpace.photoIds = files;
      }
    },


    closeSpaceDialog() {
      this.spaceDialog = false;
      this.spaceSubmitted = false;
      this.submitted = false;
      this.currentSpace = this.getObjClone( this.defaultSpace );
      this.errorKey = '';
      // this.$refs.spaceFileupload.reset();
    },

    deletePhoto( photoIndex ) {
      event.stopPropagation();
      this.toDeletePhotoIndex = photoIndex;
      this.dialogDeletePhoto = true;
    },

    async getFacilitiesApi() {
      this.facilities = await ApiRequestService.getRequest( `api/admin-facilities/list` );
      this.facilities.forEach( item => item.selected = false );
    },

    selectOrUnselectFacility( id ) {
      if ( this.selectedFacilities.indexOf( id ) === -1 ) {
        this.selectedFacilities.push( id );
      } else {
        this.selectedFacilities = this.selectedFacilities.filter( item => item !== id );
      }
    },

    async deleteOrganizationPhoto() {
      if ( !this.toDeletePhotoIndex || this.editedItem?.photos[this.toDeletePhotoIndex]?.isMain ) {
        return;
      }

      let selected = this.editedItem.photos.splice( this.toDeletePhotoIndex, 1 );
      await ApiRequestService.deleteRequest( `api/organization-photos/${ selected[0].id }` );
      this.closeDelete();
    },

    async deleteOrganizationSpace() {
      if ( !this.toDeleteSpace || !this.toDeleteSpace?.id ) {
        return;
      }

      await ApiRequestService.deleteRequest( `api/organization-spaces/${ this.toDeleteSpace.id }` );
      this.editedItem.childrenSpaces = this.editedItem.childrenSpaces.filter( item => item.id !== this.toDeleteSpace.id );
      this.closeDelete();
    },

    async selectAsOrganizationMainPhoto( photoIndex ) {
      event.stopPropagation();
      if ( !photoIndex ) {
        return;
      }

      let selected = this.editedItem.photos.splice( photoIndex, 1 );

      this.editedItem.photos.forEach( item => item.isMain = false );
      selected[0].isMain = true;

      this.editedItem.photos.unshift( selected[0] );
      await ApiRequestService.postRequest( `api/organization-photos/main/${ this.editedItem.id }`, { photoId: selected[0].id } )
    },

    openSpaceDetails( space ) {
      if ( space && space.id ) {
        let resolvedLocation = this.$router.resolve( {
          name: `space`,
          params: { spaceId: space.id }
        } )?.href;
        StorageService.setOrganizationToStorage( this.editedItem );
        console.log( 'resolvedLocation', resolvedLocation )
        this.$router.push( resolvedLocation );
      }
    },

    openMember( member ) {
      if ( member && member.userId ) {
        this.$router.push( `/user-edit/${ member.userId }` )
      }
    },

    async getOrganizationApi() {
      this.editedItem = await ApiRequestService.getRequest( `api/admin-organizations/${ this.organizationId }` );
      this.editedItem.membershipStartDate = this.getFormattedDate( this.editedItem.membershipStartDate );
      this.editedItem.membershipEndDate = this.getFormattedDate( this.editedItem.membershipEndDate );
      this.editedItem.facilities.forEach( item => {
        this.facilities.map( fa => {
          if ( fa.id === item.id ) {
            fa.selected = true
          }
        } );
        this.selectOrUnselectFacility( item.id )
      } );

      StorageService.setOrganizationToStorage( this.editedItem );
      DataEmitterService.$emit( 'route-change' );

      this.oldOrganization = this.getObjClone( this.editedItem )
      await this.organizationsUtil.getOrganizationUsers( this.oldOrganization, ( users ) => {
        users.forEach( user => {
          user.createDate = user.createDate
        } )
        this.members = users;
      } );
    },

    async getOrganizationEventsApi( organizationId ) {
      //TODO: implement API
      this.chips = null//await ApiRequestService.getRequest( `api/organization-services/${ organizationId }` );
      this.oldChips = this.chips;

      if ( this.chips?.errorMessage ) {
        this.chips = [ { id: 0, name: 'TV' }, { id: 1, name: 'Food' } ];
        this.oldChips = this.getObjClone( this.chips )
      }

      this.chipsNameList = this.chips?.map( item => item.name );
    },


    getToLocaleDate( date ) {
      return DateUtil.getToLocaleDate( date, true )
    },

    async getChangedOrganizationData( oldOrganization ) {
      const dataFormUpdate = {};

      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( oldOrganization[key] === this.editedItem[key] ) {
          continue
        }

        if ( key === 'facilities' ) {
          let readyFacilities = [];
          for ( let [ facilityKey, facilityValue ] of Object.entries( this.selectedFacilities ) ) {
            readyFacilities.push( facilityValue );
          }
          dataFormUpdate[key] = readyFacilities;
          continue;
        }

        dataFormUpdate[key] = this.editedItem[key]
      }

      return dataFormUpdate
    },


    async updateOrganizationRequest( oldOrganization ) {
      let existsPhotosCount = this.editedItem?.photos?.length;
      if ( this.editedItem?.photoIds?.length + existsPhotosCount > 10 ) {
        this.errorKey = `Maximum number of images allowed 10.You have already ${ existsPhotosCount } images`;
        return;
      }

      const dataFormUpdate = await this.getChangedOrganizationData( oldOrganization );
      const updatedOrganization = await ApiRequestService.updateRequest( `api/admin-organizations/${ this.editedItem.id }`, dataFormUpdate )
      if ( updatedOrganization.errorMessage ) {
        return
      }

      if ( updatedOrganization ) {
        this.editedItem = updatedOrganization;
        this.editedItem.membershipStartDate = this.getFormattedDate( this.editedItem.membershipStartDate );
        this.editedItem.membershipEndDate = this.getFormattedDate( this.editedItem.membershipEndDate );
      }
    },

    dataIsValid() {
      const nameIsValid = this.organizationIsValid( this.editedItem?.name?.trim() )
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }


      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    spaceDataIsValid() {
      const nameIsValid = this.organizationIsValid( this.currentSpace?.name?.trim() )
      const qrCodeIsValid = this.organizationIsValid( this.currentSpace?.qrCode?.trim() )
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }
      if ( qrCodeIsValid !== true ) {
        isNotValid.push( qrCodeIsValid )
      }


      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    organizationIsValid( v ) {
      return ( v && v.length > 0 ) || this.organizationsSnippet.required
    },


    async editItem( item ) {
      let paginatedList = this.searchField ? this.paginatedSearchedOrganizationsList : this.paginatedOrganizationsList;
      let list = this.searchField ? this.searchedOrganizationsList : this.organizationsList;
      this.editedIndex = paginatedList.findIndex( fc => fc.id === item.id );
      this.listEditedIndex = list.findIndex( fc => fc.id === item.id );
      this.editedItem = this.getObjClone( paginatedList[this.editedIndex] );

      await this.getOrganizationEventsApi( this.editedItem.id );

      this.dialog = true
      this.disableKeyField = true
      this.oldOrganization = this.getObjClone( this.editedItem )
    },

    editEvent( item ) {
      event.stopPropagation();
      this.editEventDialog = true;
      this.editedEventItem = this.getObjClone( this.eventsList ).find( event => event?.name === item );
    },

    addEvent() {
      if ( !this.newEventName ) {
        this.addEventsDialogErrorKey = 'Wrong event';
        return;
      }
      if ( this.eventsList?.findIndex( event => event.name.toLowerCase() === this.newEventName.trim().toLowerCase() ) !== -1 ) {
        this.addEventsDialogErrorKey = 'This event exists';
        return;
      }
      this.addEventsDialogErrorKey = '';
      //TODO: send add event api;
      this.eventsList.push( {
        id: this.eventsList?.length,
        name: this.newEventName
      } )
      this.oldEventsList = this.getObjClone( this.eventsList );
      this.getEditedEventsNames();
      this.newEventName = '';
    },

    deleteSelectedEvent( item ) {
      event.stopPropagation();
      this.chips = this.chips.filter( chip => chip.name !== item );
      this.getEditedEventsNames();
    },
    deleteEvent( item ) {
      event.stopPropagation();
      this.toDeleteEvent = item;
      this.deleteEventDialog = true;
    },

    deleteSpaceItem( item ) {
      let toDeleteIndex = this.organizationSpaces.findIndex( fs => fs.id === item.id );
      this.toDeleteSpace = this.getObjClone( this.organizationSpaces[toDeleteIndex] );
      this.dialogDeleteSpace = true
    },

    upgradeItem( item ) {
      this.editedIndex = this.organizationsList.indexOf( item )
      this.dialogUpgrade = true
    },


    async deleteEventConfirm() {
      this.eventsList = this.eventsList?.filter( chip => chip.name !== this.toDeleteEvent );
      this.oldEventsList = this.getObjClone( this.eventsList );
      this.getEditedEventsNames();
      //TODO: send delete event request

      this.closeDeleteEvent()
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) );
    },

    closeDelete() {
      this.dialogDelete = false;
      this.dialogDeleteSpace = false;
      this.dialogDeletePhoto = false;
      this.submitted = false;
    },

    closeDialogCarousel() {
      this.dialogCarousel = false;
      this.carouselPhotos = {}
    },

    closeDeleteEvent() {
      this.deleteEventDialog = false;
    },

    closeUpgrade() {
      this.dialogUpgrade = false;
      this.submitted = false;
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) };
        this.editedIndex = -1;
      } )
    },

    generateRandomUniqueString( len ) {
      let text = '';
      for ( let i = 0; i < len; i++ ) {
        text += Math.random().toString( 36 ).slice( 2 ) + Date.now()
      }

      return text
    },


    async uploadOrganizationImages( dataToCreate ) {
      let photoIds = [];

      for ( let i = 0; i < dataToCreate?.photoIds?.length; i++ ) {
        const formData = new FormData();
        formData.append( "file", dataToCreate?.photoIds[i], dataToCreate?.photoIds[i].name );

        let data = await ApiRequestService.postRequest( 'api/organization-photos', formData );
        photoIds.push( data?.id );
      }

      return photoIds;
    },
    async uploadOrganizationSpaceImages( dataToCreate ) {
      let photoIds = [];

      for ( let i = 0; i < dataToCreate?.photoIds?.length; i++ ) {
        const formData = new FormData();
        formData.append( "file", dataToCreate?.photoIds[i], dataToCreate?.photoIds[i].name );

        let data = await ApiRequestService.postRequest( 'api/organization-photos/organization-space-photos', formData );
        photoIds.push( data?.id );
      }

      return photoIds;
    },

    async updateOrganization() {
      let isValid = this.dataIsValid();
      if ( isValid !== true ) {
        this.globalError = isValid;
        return;
      }

      await this.updateOrganizationRequest( this.oldOrganization );
    },

    async save() {
      this.submitted = true;
      await this.updateOrganization();
    },

    showErrorDialog() {
      this.emptyReportData = true;
    },
    closeDialog() {
      this.emptyReportData = false;
    },


    changeDate() {
      if ( this.reportEndDate < this.reportStartDate ) {
        this.reportEndDate = '';
        this.reportStartDate = '';
        return;
      }

      this.menu = false;
      this.downloadReport( this.reportStartDate, this.reportEndDate ).then(() => {
        this.reportEndDate = '';
        this.reportStartDate = '';
      })
    },

    async downloadReport( startDate, endDate ) {
      const reportData = await ApiRequestService.postRequest( `api/admin-organizations/report/${ this.organizationId }`, {
        startDate,
        endDate
      } );
      if ( !reportData || !reportData.length ) {
        this.showErrorDialog();
        return;
      }

      this.reportHeader = Object.keys( reportData[0] );
      this.reportHeader = this.reportHeader.map( item => item.includes( '_' ) ? item.split( '_' ).join( ' ' ) : item );

      let csvData = [];
      reportData.forEach( data => {
        const rowValue = [];
        for ( let [ key, value ] of Object.entries( data ) ) {
          if ( key === 'Spent_credits' ) {
            rowValue.push( value * -1 );
          } else {
            rowValue.push( value );
          }
        }
        csvData.push( rowValue );
      } );
      const totalData = [];
      let totalAmount = 0;
      this.reportHeader.forEach( _ => {
        totalData.push( ' ' );
      } )
      reportData.forEach( item => {
        totalAmount += parseFloat( item.Spent_credits ) * -1 ?? 0;
      } )

      this.reportHeader.push( 'Total Spent Credits' )
      csvData.unshift( this.reportHeader );

      totalData.push( totalAmount )
      csvData.push( totalData );

      let csvContent = 'data:text/csv;charset=utf-8,';
      csvData.forEach( ( row ) => {
        const csvRow = row.join( ',' );
        csvContent += csvRow + '\r\n';
      } );

      // Create a temporary anchor element to download the CSV file
      const encodedUri = encodeURI( csvContent );
      const link = document.createElement( 'a' );
      link.setAttribute( 'href', encodedUri );
      link.setAttribute( 'download', this.editedItem.name + '-report-' + moment().days( -30 ).format( 'MM-DD-YYYY' ) + '-to-' + moment().format( 'MM-DD-YYYY' ) + '.csv' );
      document.body.appendChild( link );

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild( link );
    },

    getEditedEventsNames() {
      this.chipsNameList = this.chips?.map( item => item.name );
      this.eventsNameList = this.eventsList?.map( item => item.name );
    },

    saveEditedEventItem() {
      let editedEventItemIndex = this.eventsList?.findIndex( item => item.id === this.editedEventItem.id );
      this.$set( this.eventsList, editedEventItemIndex, this.editedEventItem );

      this.chipsNameList.forEach( item => {
        let selectedEditedEventItemIndex = this.chips.findIndex( chip => chip.name === item );

        let editedEventItemIndex = this.oldEventsList.findIndex( chip => chip.name === item );
        let editedEventItem = this.eventsList[editedEventItemIndex];

        if ( selectedEditedEventItemIndex !== -1 ) {
          this.$set( this.chips, selectedEditedEventItemIndex, {
            id: editedEventItem.id,
            name: editedEventItem.name
          } );
        } else {
          if ( editedEventItem ) {
            this.chips.push( {
              id: editedEventItem.id,
              name: editedEventItem.name,
            } )
          }
        }
      } )

      this.getEditedEventsNames();

      this.eventsListHasBeenEdited = true;
      this.closeEditEventDialog();
    },

    getDayName( workDay ) {
      if ( !workDay ) {
        return
      }

      switch ( workDay.workday ) {
        case 0:
          return 'Monday'
        case 1:
          return 'Tuesday'
        case 2:
          return 'Wednesday'
        case 3:
          return 'Thursday'
        case 4:
          return 'Friday'
        case 5:
          return 'Saturday'
        case 6:
          return 'Sunday'
      }
    },

    saveEvents() {
      this.oldChips = this.getObjClone( this.chips );
      if ( this.eventsListHasBeenEdited ) {
        //TODO: send events list update request
      }
      //TODO: end current organization events list update request
      this.closeEventDialog();
    },


    setPhotos( files ) {
      if ( files && files.length ) {
        if ( files.length + this.editedItem.photos.length > 10 ) {
          this.errorKey = 'Is not able to upload more then 10 images';
          this.$refs.fileupload.reset();
        } else {
          this.errorKey = '';
          this.editedItem.photoIds = files;
        }
      }
    },

    getFormattedDate( date ) {
      if ( date ) {
        date = new Date( date );
      } else {
        date = new Date();
      }
      let year = date.getFullYear();
      let month = ( 1 + date.getMonth() ).toString().padStart( 2, '0' );
      let day = date.getDate().toString().padStart( 2, '0' );

      return year + '-' + month + '-' + day;
    }
  }
}

